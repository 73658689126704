import React from "react"
import Seo from "../components/Seo"
import Contact from "../components/Contact"
const contact = () => {
  return (
    <>
      <Seo title="İletişim" />
      <main>
        {" "}
        <Contact></Contact>
      </main>
    </>
  )
}

export default contact
